.p-selectbutton {
    .p-button {
        background: $toggleButtonBg;
        border: $toggleButtonBorder;
        color: $toggleButtonTextColor;
        transition: $formElementTransition;

        .p-button-icon-left,
        .p-button-icon-right {
            color: $toggleButtonIconColor;
        }

        &:not(.p-disabled):not(.p-highlight):hover {
            background: $toggleButtonHoverBg;
            border-color: $toggleButtonHoverBorderColor;
            color: $toggleButtonTextHoverColor;

            .p-button-icon-left,
            .p-button-icon-right {
                color: $toggleButtonIconHoverColor;
            }
        }

        &:first-of-type {
          border-radius: 20px 0 0 20px !important;
        }

        &:last-of-type {
          border-radius: 0 20px 20px 0 !important;
        }

        &.p-highlight {
            background: #fff !important;
            border-right: 2px solid !important;
            border-color: $mainErrorColor !important;
            color: $toggleButtonTextColor;

            & + .p-button {
              border-left: none !important
            }


            .p-button-icon-left,
            .p-button-icon-right {
                color: $toggleButtonIconActiveColor;
            }

            &:hover {
                background: #fff !important;
                border-color: $mainErrorColor !important;
                color: $toggleButtonTextColor;

                .p-button-icon-left,
                .p-button-icon-right {
                    color: $toggleButtonIconActiveHoverColor;
                }
            }
        }
    }
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
    @include invalid-input();
}
