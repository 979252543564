@import "assets/themes/mytheme/theme.scss";
@import "assets/themes/variables/taiga";

@import "primeicons/primeicons.css";
@import '@angular/cdk/overlay-prebuilt.css';



/* You can add global styles to this file, and also import other style files */
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

html {
  font-size: 14px;
}

.tui-row {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.p-inputtextarea {
  resize: none;
}


.select {
  background: #ffffff;
  min-height: 46px;

  &-origin {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 39px;
    padding: $inputPadding;
    cursor: pointer;
    border-bottom: $mainInputBorderBottom;
    min-height: 39px;
    &.readonly {
      opacity: 0.8;
      cursor: default;
    }
    &:not(.readonly):hover {
      border-color: $primaryColor;
    }
    &.focus {
      border-color: $primaryColor;
    }

    .select-loader {
      margin-left: auto;
    }
    button {
      height: 100%;
      border: none;
      outline: none;
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $mainTextColor;
    }

    &.multiple-selected {
      padding: calc((39px - 27.5px) / 2) 0;
    }

    &-content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 3px 5px;
      &-remove {
        width: 39px;
        height: 100%;
        border: none;
        outline: none;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #3C476066;
        cursor: pointer;
        margin-left: auto;
      }
      &-selected {
        font-size: 1rem;
        color: $mainTextColor;
        &.chip {
          padding: 5px .75rem;
          border-radius: 2rem;
          color: $mainTextSecondaryColor;
          background: $mainBgSecondaryColor;
          display: flex;
          align-items: center;
          button {
            width: 12px;
            height: 12px;
            border: none;
            outline: none;
            background: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $mainTextSecondaryColor;
            cursor: pointer;
            i {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  &-overlay {
    display: flex;
    flex-direction: column;
    width: 100%;
    &.select-body-top {
      flex-direction: column-reverse;
    }
    .select-origin {
      background-color: #fff;
    }
  }

  &-option {
    height: 46px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #4b5563;
    font-size: 16px;
    padding: 0 10px;
    transition: .3s;
    &:hover {
      color: #4b5563;
      background: #f3f4f6;
    }
  }

  &-dropdown {
    &-search {
      padding: 0.5rem 0;
      width: calc(100% - 2rem);
      margin: 0 auto 0.5rem;
      display: block;
      input {
        width: 100%;
      }

      & + .select-dropdown-options {
        min-height: calc(254px - 0.5rem - 0.5rem) !important;
      }
    }
    &-options {
      &.not-full {
        &.has-filter {
          min-height: calc((46px * var(--select-elements)) + 56px);
          max-height: calc((46px * var(--select-elements)) + 56px);
          .p-tree {
            height: calc(((46px * var(--select-elements)) + 56px) - 0.5rem) !important;
          }
        }

        &:not(.has-filter) {
          min-height: calc(46px * var(--select-elements));
          max-height: calc(46px * var(--select-elements));

          .p-tree {
            height: calc((46px * var(--select-elements)) - 0.5rem) !important;
          }
        }
      }
      &:not(.not-full) {
        min-height: 307px;
      }
      .p-tree {
        padding: 0;
        height: 300px;
        border: none;
        &-filter-container {
          padding: 0.5rem 0;
          width: calc(100% - 2rem);
          margin: 0 auto 0.5rem;
        }
      }
      &.selected-dropdown-option-filter {
        .p-tree {
          padding: 0;
          height: calc(254px - 0.5rem);
          border: none;
          position: relative;

          .p-tree-empty-message {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            trasnform: translateY(-50%);
            font-size: 1rem;
            color: $textColor;
            text-align: center;
          }
        }
      }
    }
  }
}

app-form-select.ng-invalid.ng-touched {
   .select-origin {
    border-color: $mainErrorColor;
  }
}

app-select.ng-invalid.ng-touched {
  .select-origin {
    border-color: $mainErrorColor;
  }
}

app-date-picker.ng-invalid.ng-touched {
  .p-inputtext {
    border-color: $mainErrorColor;
  }
}

app-input.ng-invalid.ng-touched {
  .p-inputtext {
    border-color: $mainErrorColor;
  }
}

.p-float-label label {
  margin-left: 0.5rem;
}

.checkbox {
  .p-tree {
    padding: 0;
    border: none;
  }
}

.p-datepicker-calendar {
  thead th {
    font-weight: 400;
    color: $mainTextSecondaryColor;
  }

  tbody tr {
    td:nth-last-child(-n+2) {
      span {
        &:not(.p-highlight) {
          color: $mainErrorColor;
        }
      }
    }
  }
}

label {
  font-size: 12px;
  color: $mainTextSecondaryColor;
}

.p-treenode-label {
  display: block;
  flex: 1;
  min-width: 0;
  span {
    display: block;
  }
}


.p-buttonset {
  app-button {
    &:first-child {
      button {
        border-radius: 2rem 0 0 2rem
      }
    }

    &:last-child {
      button {
        border-radius: 0 2rem 2rem 0
      }
    }
  }
}

input, textarea {
  font-size: 1rem;
}

.p-tree-container {
  max-width: 100%;
}

.modal {
  flex: 1;
  min-height: 0;
  display: grid;
  grid-template-rows: max-content minmax(0, 1fr) max-content;
  &-parent {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
  }
  &-content {
    margin-right: -24px;
    &_box {
      padding-right: 24px;
    }
  }

  &-footer {
    margin: 0 -24px;
    padding: 0 24px 24px 24px;
  }
}

.floated {
  position: relative;
  padding-top: 15px;
  &-label {
    transition: .15s ease-in-out;
    padding-left: 10px;
    position: absolute;
    left: 0;
    top: 28px;
    z-index: 100;
    &.floated-active {
      top: 0;
      padding-left: 0;
    }
  }
}

.action-panel {
  .t-header {
    padding: 0 !important;

  }

  .t-group {
    gap: 10px;
  }

  tui-accordion-item > .t-wrapper {
    border-radius: 6px !important;
    border: 1px solid #DEE2E6;
  }

  .t-content {
    padding: 0 !important;
  }
}

.ripple {
  position: relative;
  overflow: hidden;

  &-ink {
    position: absolute;
    border-radius: 100%;
    background: currentColor;
    opacity: 0.12;
    pointer-events: none;
    animation-duration: 450ms;
    animation-name: rippleOn;
  }
}

@keyframes rippleOn {
  from {
    transform: scale(0);
  }

  to {
    transform: none;
  }
}

@keyframes rippleOff {
  from {
    transform: none;
  }

  to {
    transform: none;
    opacity: 0;
  }
}

.accordion {
  .t-header {
    padding: 0 !important;
    min-height: max-content !important;
  }

  .t-group {
    max-height: 100%;
    gap: 2px;
  }

  tui-accordion-item {
    & > .t-wrapper {
      border: none;
    }
  }

  .t-content {
    padding: 0 !important;
  }
}

tui-dropdown[data-appearance='menu-drop'] {
  border-radius: 8px;
  box-shadow: 0px 25px 15px 0px rgba(0, 0, 0, 0.03), 0px 11px 11px 0px rgba(0, 0, 0, 0.04), 0px 3px 6px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid #F5F7FA;
  background: #FFF;
}

.counter {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #8088981F;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000E5;
  font-size: 11px;
  font-weight: 500;
  font-family: var(--manrope-font);
}

.action-menu {
  .p-menu.p-menu-overlay {
    min-width: 320px;
  }
}

.tui-text_h6 {
  font-size: 22px;
  font-weight: 600;
  color: #000000E5;
  font-family: 'Fira Sans';
}

.modal-description {
  margin: 0 0 15px 0;
  font-size: 13px;
  color: #000000E5;
  line-height: 20px;
}

app-action-button {
  .p-button {
    padding-left: 17.5px;
    &-label {
      padding-right: 17.5px;
    }
  }
}

p-table {
  .p-dropdown {
    border-radius: 0;
  }
}

.access {
  .p-paginator {
    padding-top: 0;
    height: 50px;
    align-items: flex-end;

    .p-dropdown {
      height: 38px;
    }
  }
}
