.p-menu {
    padding: $verticalMenuPadding;
    background: $menuBg;
    color: $menuTextColor;
    border: $menuBorder;
    border-radius: $borderRadius;
    width: $menuWidth;
    box-shadow: 0px 25px 15px 0px rgba(0, 0, 0, 0.03), 0px 11px 11px 0px rgba(0, 0, 0, 0.04), 0px 3px 6px 0px rgba(0, 0, 0, 0.05);

    .p-menuitem {
        @include menuitem();
    }

    &.p-menu-overlay {
        background: $overlayMenuBg;
        border: $overlayMenuBorder;
        box-shadow: $overlayMenuShadow;
        left: unset;
        top: unset;
        bottom: -5px;
        right: 0;
        transform: translateY(100%);
        min-width: 240px;
        max-width: 80vw;
    }

    .p-submenu-header {
        margin: $submenuHeaderMargin;
        padding: $submenuHeaderPadding;
        color: $submenuHeaderTextColor;
        background: $submenuHeaderBg;
        font-weight: $submenuHeaderFontWeight;
        border-top-right-radius: $submenuHeaderBorderRadius; 
        border-top-left-radius: $submenuHeaderBorderRadius; 
    }

    .p-menuitem-separator {
        border-top: $divider;
        margin: $menuSeparatorMargin;
    }

    .p-menuitem-badge {
        @include menuitem-badge();
    }
}
