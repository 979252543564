$mainTextColor: #3C3C3C;
$mainTextSecondaryColor: #495057;
$mainErrorColor: #FF238D;
$mainBorderColor: #BFC9E0;
$mainBgColor: #F5F7FA;
$mainBgSecondaryColor: #DEE2E6B2;


//inputs
$mainInputBorder: 1px solid $mainBorderColor;
$mainInputBorderBottom: $mainInputBorder;
$mainInputBorderTop: none;
$mainInputBorderLeft: none;
$mainInputBorderRight: none;
$mainInputBorderRadius: 0;
$mainInputTextareaBorderRadius: 12px;
$mainInputTextareaPadding: .75rem .5rem;

$primaryColor: #0066FF;
$primaryHoverColor: #1A75FF;
$primaryActiveColor: #0066FF;
$primaryDarkColor: #1E56AA;
$primaryDarkerColor: #1951a4;
$primaryTextColor: #ffffff;

$manropeFont: "Manrope", sans-serif;

:root {
  --manrope-font: #{$manropeFont};
}
