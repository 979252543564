@import "variables";

:root {
  --tui-primary: #{$primaryColor};
  --tui-primary-hover: #{$primaryDarkColor};
  --tui-primary-active:#{$primaryDarkerColor};
  --tui-secondary: #{$mainTextColor};
  --tui-secondary-hover: #{darken($mainTextColor, 5%)};
  --tui-secondary-active: #{darken($mainTextColor, 10%)};
  --tui-accent: #{$mainErrorColor};
  --tui-accent-hover: #{darken($mainErrorColor, 5%)};
  --tui-accent-active: #{darken($mainErrorColor, 10%)};
  --tui-error-fill: #{$mainErrorColor};
}
